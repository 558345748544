import React, { useContext, useMemo, useEffect } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'

import ModalLayout from '../components/Layouts/ModalLayout'
import { ModalContentHeader } from '../components/ModalHeader'
import ImagesShuffle from '../components/Lists/ListShuffle/ImagesShuffle'
import ListProjects from '../components/ListProjects'
import DropdownFaq from '../components/DropdownFaq'

import { capitalizeFirstLetter, getConstrastColor, getPackColor, gtagContactConversion, lowercaseFirstLetter } from '../utils/helpers/utils'
import { black, white } from '../utils/constants/colors'
import { nbFeaturedProjectsPack } from '../utils/constants/global'

import { formatNodeProjectToProject } from '../utils/helpers/projects'
import { internationalRoutes, routeNames } from '../utils/helpers/routes'
import { getPrismicListDocument } from '../utils/helpers/transformers'
import { HistoryContext } from '../utils/hooks/History'
import { animateEntry, setEntry } from '../utils/page-animations/pack'
import { animateModalHeaderContent, setModalHeaderContent } from '../utils/page-animations/common'

import ScrollTrigger from 'gsap/ScrollTrigger'
import useAnimationState from '../utils/hooks/useAnimationState'

const Pack = (props) => {
  const { data, pageContext } = props
  const { routeName, language } = pageContext

   // INTN
  const { t } = useTranslation(['pack', 'common'])
  const localizedRoutePacks = internationalRoutes[routeNames.packages].langs[language];
  
  // ANIMATION
  const animationState = useAnimationState({ routeName })

  // CONTEXTS
  const { isLandingPage } = useContext(HistoryContext)
  
  //DATA
  const needsLandingContent = useMemo(() => animationState.isEntryPage && isLandingPage, [animationState.isEntryPage, isLandingPage])
  const pack = useMemo(() => data.prismicPack?.data, [data])

  const packColor = getPackColor(pack.field)
  const primaryIsLightContrast = getConstrastColor(packColor) === "light";
  const primaryTextColor = primaryIsLightContrast ? black : white;
  const styleSection = { backgroundColor: packColor, color: primaryTextColor }


  const featuredProjects = useMemo(() => {
    const docs = getPrismicListDocument(pack, 'projects', 'project');
    return docs?.slice(0, nbFeaturedProjectsPack)
  }, [pack])

  const moreProjects = useMemo(() => pack.projects
    .slice(nbFeaturedProjectsPack)
    .reduce((acc, item) => {
      const project = formatNodeProjectToProject(item.project.document);
      if (project) {
        acc.push(project);
      }
      return acc;
  }, []), [pack.projects]);

  const packName = pack.name?.text
  const packField = pack.field;
  const packTitle = pack.title?.text;
  const packDescription = pack.description?.text;
  const seo = {
    title: t('pack:seo:title', {
      name: packName,
      field: packField && capitalizeFirstLetter(packField),
      title: packTitle
    }),
    description: t('pack:seo:description', {
      field: packField,
      description: packDescription && lowercaseFirstLetter(packDescription),
    }),
  }
  const landingLink = {
    url: localizedRoutePacks,
    label: t('pack:landing_link:label'),
    entryTransitionType: "slideIn",
    exitTransitionType: "none",
  }
  const logo = `/images/pack-${packField}.svg`
  const pageLogo = useMemo(() => ({
    img: logo,
    width: 69,
    height: 43
  }), [logo])

  // HOOKS
  useEffect(() => {
    if (!animationState.canStart) {
      return
    }
    ScrollTrigger.clearScrollMemory()
    setEntry()
  }, [animationState.canStart])


  useEffect(() => {
    if (!animationState.canStart || !needsLandingContent) {
      return
    }
    ScrollTrigger.clearScrollMemory()
    setModalHeaderContent()
  }, [animationState.canStart, needsLandingContent])

  useEffect(() => {
    if (!animationState.canPlay) {
      return
    }
    
    ScrollTrigger.clearScrollMemory()
    ScrollTrigger.refresh()

    const tls = animateEntry()

    return () => {
      tls.forEach((tl) => tl && tl.kill())
    }
  }, [animationState.canPlay])


  useEffect(() => {
    if (!animationState.canPlay || !needsLandingContent) {
      return
    }
    
    ScrollTrigger.clearScrollMemory()
    ScrollTrigger.refresh()
    const tl = animateModalHeaderContent('.tpl-pack')
    return () => tl && tl.kill()
  }, [animationState.canPlay, needsLandingContent])

  return (
    <ModalLayout
      name={routeNames.pack}
      seo={seo}
      title={capitalizeFirstLetter(packName)}
      subtitle={capitalizeFirstLetter(packField)}
      headerBgColor={packColor}
      logo={pageLogo}
      uri={props.uri}
      language={language}
      internationalRoute={props.pageContext.internationalRoute}
      landingLink={landingLink}
      isLandingPage={needsLandingContent}
    >
    <section className="pb-6 pb-b-md-1" style={styleSection}>
      <div className="container">
        {needsLandingContent &&
          <ModalContentHeader
            title={capitalizeFirstLetter(packName)}
            subtitle={capitalizeFirstLetter(packField)}
            logo={pageLogo}
          />
        }
        <div className="row pt-5 pt-md-8 ">
          <div className="col col-lg-offset-1 col-lg-11">
            {packTitle && <h1 className="h2 ft-500 page__title ft-no-select" aria-label={packTitle}>{packTitle}</h1>}
          </div>
        </div>
        <div className="row mt-3 mt-md-5">
          <div className="col col-lg-offset-1 col-lg-8">
            {packDescription && (
              <p className="h5 ft-300 mb-0 page__desc">{packDescription}</p>
            )}
            <div className="mt-3 mt-md-6 f f-ai-center f-direction-row page__info">
              <a href={`mailto:${t('common:contact:email')}?subject=${encodeURI(packName)}`} onClick={gtagContactConversion} className="btn btn--small btn--primary border-w-4">
                <span className="hide-mobile">{t('pack:cta')}</span>
                <span className="hide-desktop">{t('pack:cta_mobile')}</span>
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-lg-offset-1 col-lg-12">
            <ul className="list-template-pack mt-7 mt-md-8">
              {pack.steps.map((step, index) => {
                return (
                  <li className="list-template-pack__item f f-jc-space-b" key={index}>
                    <div>
                      <div className="step-title">{step.step_title.text}</div>
                      <div className="ft-default-m-small">{step.step_description.text}</div>
                    </div>
                  </li>
                )
              })}
            </ul>
            {pack.mentions && pack.mentions.text && <p className="page__mentions ft-secondary mt-2 mb-0">{pack.mentions.text}</p>}
          </div>
        </div>
      </div>
    </section>
    <section className="pt-6 pb-6 pt-md-8 pb-b-md-1 tpl-pack__bottom">
      <div className="container">
        {(pack.faq.length > 0) && 
          <div className="row">
            <div className="col col-lg-offset-1 col-lg-7 mb-6 mb-md-8 faq">
              <h3 className="ft-f-default h6 ft-400 mb-3 faq__title">{t('pack:faq:title')}</h3>
              <DropdownFaq data={pack.faq} className="faq__list" />
            </div>
          </div>
        }

        {featuredProjects.length > 0 && (
          <div className="row mb-3 mb-md-5">
            <div className="col col-lg-offset-1 col-lg-12">
              <h2 className="ft-f-default h6 ft-400 mb-3 mb-md-4">{t('pack:featured_projects')}</h2>
              <div className="shuffle shuffle--list">
                <ul>
                  {featuredProjects.map((project, idx) => (
                    <ImagesShuffle
                      uid={project.uid}
                      key={project.uid}
                      index={idx}
                      showTitle={true}
                      title={project.data?.name?.text}
                      hasPage={project?.data.has_page && !(project?.data.has_nda)}
                      client={project.data?.client?.document?.data?.name?.text}
                      thumbnail={project.data.thumbnail}
                      hoverImages={project.data.snapshots}
                      alt={`${project.data.client.document.data.name.text} — ${project.data.name.text} illustration`}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
        {moreProjects.length > 0 && (
          <div className="row mt-4 mt-md-8">
            <div className="col col-lg-offset-1 col-lg-12">
              <h2 className="ft-f-default h6 ft-400 mb-3 mb-md-4">{t('pack:more_projects')}</h2>
              <ListProjects light projects={moreProjects} />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col pt-6 hide-desktop">
            <a href={`mailto:${t('common:contact:email')}`} onClick={gtagContactConversion} className="btn btn--center btn--small btn--dark btn--full border-w-0">
              <span>{t('pack:cta_mobile')}</span>
            </a>
          </div>
        </div>
      </div>
    </section>
    </ModalLayout>
  )
}

export const queryHome = graphql`
  query ($uid: String, $language: String, $contentLang: String) {
    locales: allLocale(filter: { language: {eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismicPack(uid: { eq: $uid }, lang: { eq: $contentLang }) {
      data {
        ...prismicPackData
      }
    }
  }
`

export default Pack
