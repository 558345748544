import React, { useRef, useCallback } from 'react'
import { iconPolygon } from '../../utils/icons'

const DropdownFaq = (props) => {
  const { data } = props
  const dropdowns = useRef()

  const onClickDropdown = useCallback((e) => {
    const listDropdown = dropdowns.current.querySelectorAll('.dropdown')
    const activeDropdown = e.currentTarget.parentElement

    if (activeDropdown.classList.contains('is-active')) {
      activeDropdown.classList.remove('is-active')
      return
    }

    for (let i = 0; i < listDropdown.length; i++) {
      const currentDropdown = listDropdown[i]
      currentDropdown.classList.remove('is-active')
    }

    activeDropdown.classList.toggle('is-active')
  }, [])

  return (
    <ul ref={dropdowns} className={props.className}>
      {data.map((faq, index) => {
        const hasDescription =
          (faq.faq_description &&
            faq.faq_description[0] &&
            faq.faq_description[0].text) ||
          (faq.faq_description && faq.faq_description.text)

        const hasTitle =
          (faq.faq_title && faq.faq_title[0] && faq.faq_title[0].text) ||
          (faq.faq_title && faq.faq_title.text)

        return (
          hasTitle && (
            <li
              className={`dropdown ${hasDescription ? 'is-toggable' : ''}`}
              key={index}
            >
              <div
                role="button"
                tabIndex={0}
                className="dropdown__top"
                onKeyPress={(e) => onClickDropdown(e)}
                onClick={(e) => onClickDropdown(e)}
              >
                {hasDescription && (
                  <span className="dropdown__toggle mr-3">{iconPolygon}</span>
                )}
                <div className="ft-secondary">
                  {(faq.faq_title[0] && faq.faq_title[0].text) ||
                    faq.faq_title.text}
                </div>
              </div>
              {hasDescription && (
                <div className="dropdown__bottom ft-secondary">
                  <p className="c-gray-700">
                    {(faq.faq_description[0] && faq.faq_description[0].text) ||
                      faq.faq_description.text}
                  </p>
                </div>
              )}
            </li>
          )
        )
      })}
    </ul>
  )
}

export default DropdownFaq
