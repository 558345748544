import gsap from "gsap"
import { animateModalEntry, animatePackListEntry, gsapParams, setModalEntry, setPackListEntry } from "./common"

const selectors = {
  wrapper: '.tpl-pack',
  page: {
    bottom: '.tpl-pack__bottom',
  },
  faq: {
    wrapper: '.faq',
    items: '.faq__list .dropdown',
    title: '.faq__title'
  },
  shuffle: {
    wrapper: '.shuffle',
    items: '.shuffle__item',
    cta: '.shuffle__cta'
  },
  price: {
    wrapper: '.table-prices',
    row: '.table-prices tr'
  }
}

const setShuffleEntry = () => {
  const cta = document.querySelector(selectors.shuffle.cta)

  gsap.set(selectors.shuffle.items, { ...gsapParams.fadeOut })
  cta && gsap.set(cta, { ...gsapParams.fadeOut })
}

const setFAQListEntry = () => {
  if (!document.querySelector(selectors.faq.wrapper)) {
    return
  }

  gsap.set([selectors.faq.title, selectors.faq.items], { ...gsapParams.fadeOut })
}

const animateShuffleEntry = (wrapper) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: wrapper,
      start: 'top 80%',
      end: 'bottom center',
      once: true
    }
  })

  const cta = document.querySelector(selectors.shuffle.cta)

  tl.to(selectors.shuffle.items, { ...gsapParams.fadeIn, stagger: 0.05 }, 0)
  cta && tl.to(cta, { ...gsapParams.fadeIn }, .3)
  return tl
}

const animatFAQListEntry = (wrapper) => {
  if (!document.querySelector(selectors.faq.wrapper)) {
    return
  }

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: wrapper,
      start: 'top 80%',
      end: 'bottom center',
      once: true
    }
  })

  tl.to(selectors.faq.title, { ...gsapParams.fadeIn }, 0)
  tl.to(selectors.faq.items, { ...gsapParams.fadeIn, stagger: 0.05 }, .15)
  return tl
}

const setEntry = () => {
  setModalEntry({
    onlyFadeInDesc: true
  })
  setFAQListEntry()
  setPackListEntry()
  setShuffleEntry()
}

const animateEntry = () => {
  const tl = []
  tl.push(animateModalEntry(selectors.wrapper, {
    onlyFadeInDesc: true
  }))
  tl.push(animatePackListEntry(selectors.wrapper))
  tl.push(animatFAQListEntry(selectors.page.bottom))
  tl.push(animateShuffleEntry(selectors.page.bottom))

  return tl
}

export {
  animateEntry,
  setEntry
}